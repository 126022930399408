import { createContext } from 'react'; 

export interface ProAgricaAuth {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
}
export type RogoPortalUser = {
    id: string;
    name: string;
    token: string;
    username: string,
    email: string,
    full_name: string,
    disabled: boolean,
    company: string[],
    proagrica_auth?: ProAgricaAuth,
}


//set initial value of user to null (pre-login)
export const UserContext = createContext<RogoPortalUser>({} as RogoPortalUser);