import { Box, Button, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { reset_password } from '../api/airtable_ops';
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from './LoadingComponent';

interface PasswordResetProps {
    setLoggedIn: (user: any) => void;
}

export default function PasswordReset(props: PasswordResetProps) {
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [errors, setErrors] = React.useState('');
    const [loading, setLoading] = React.useState(false);


    const navigate = useNavigate();

    const handlePasswordReset = async (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (emailError) {
            setLoading(false);
            return;
        }
        setErrors('');


        try {
            await reset_password(email);
            alert('Password reset successfully. Check your email for the new password.');
            navigate('/login');
        } catch (error: any) {
            setErrors(error.message);
        } finally {
            setLoading(false);
        }
    };

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmailError('');
        // validate that username is an email with regex
        if (!emailPattern.test(event.target.value.toString())) {
            setEmailError("Invalid email address");
        }

        setEmail(event.target.value);
    };

    return (
        <Box
            maxWidth="md"
            margin="auto"
            component="form"
            noValidate
            sx={{
                mt: 1,
            }}
            onSubmit={handlePasswordReset}
        >
            {(loading) && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box>
            )}
            <TextField
                margin="normal"
                required
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error = {!!emailError}
                helperText = {emailError}
                onBlur = {handleEmailChange}
            />
            {errors &&
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ color: 'red' }}
                >
                    {errors}
                </Typography>}
            <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                Please enter the email associated with your Rogo Account.
            </Typography>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
            Reset Password</Button>

        </Box>

    );
}