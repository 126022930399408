import { Button, LinearProgress } from "@mui/material";
import { RogoPortalUser } from "../hooks/UserContext";
import React from "react";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


interface JobImportProcessOps {
    currentUser: RogoPortalUser;
}

const steps = [
    '1. Select Method',
    '2. Login',
    '3. Select Grower(s)',
    '4. Select Field(s)',
    '5. Configure Fields',
    '6. Review & Submit',
] as const;

type JobImportProcessStep = typeof steps[number]

export default function JobImportProcess(props: JobImportProcessOps) {
    const [progress, setProgress] = React.useState(20);
    const [step, setStep] = React.useState<JobImportProcessStep>('1. Select Method');
    return (
        <div>
            {step === '1. Select Method' && (
                <div>
                    <h1>Select a data source to get started</h1>
                    {/* <Grid container maxWidth={400} direction='column' justifyItems='center' justifyContent='center' spacing={2}>
                        <Grid>
                            <Button variant='contained'>Upload CSV file</Button>
                        </Grid>
                        <Grid>
                            <Button variant='contained'>Copy and paste CSV data</Button>
                        </Grid>
                        <Grid>
                            <Button variant='contained'>Manual Data Entry</Button>
                        </Grid>
                        <Grid>
                            <Button variant='contained'>Connect To Proagrica Sirrus</Button>
                        </Grid>
                        <Grid>
                            <Button variant='contained'>Connect To AgVance Sky Mapping</Button>
                        </Grid>
                    </Grid> */}
                </div>
            )}
            {/* Pin this to the bottom of the page */}
            <LinearProgress
                style={{ position: 'fixed', bottom: 0, left: 0, right: 0, height: 20 }}
                variant="determinate"
                value={progress} />
        </div>
    )
}
